const countryCode = ['ALL', 'AD','AE','AF','AG','AI','AL','AM','AN','AO','AQ','AR','AS','AT','AU','AW','AX','AZ','BA','BB','BD','BE','BF','BG','BH','BI','BJ','BL','BM','BN','BO','BQ','BR','BS','BT','BV','BW','BY','BZ','CA','CC','CD','CF','CG','CH','CI','CK','CL','CM','CN','CO','CR','CU','CV','CW','CX','CY','CZ','DE','DJ','DK','DM','DO','DZ','EC','EE','EG','EH','ER','ES','ET','FI','FJ','FK','FM','FO','FR','GA','GB','GD','GE','GF','GG','GH','GI','GL','GM','GN','GP','GQ','GR','GS','GT','GU','GW','GY','HK','HM','HN','HR','HT','HU','ID','IE','IL','IM','IN','IO','IQ','IR','IS','IT','JE','JM','JO','JP','KE','KG','KH','KI','KM','KN','KP','KR','KW','KY','KZ','LA','LB','LC','LI','LK','LR','LS','LT','LU','LV','LY','MA','MC','MD','ME','MF','MG','MH','MK','ML','MM','MN','MO','MP','MQ','MR','MS','MT','MU','MV','MW','MX','MY','MZ','NA','NC','NE','NF','NG','NI','NL','NO','NP','NR','NU','NZ','OM','PA','PE','PF','PG','PH','PK','PL','PM','PN','PR','PS','PT','PW','PY','QA','RE','RO','RS','RU','RW','SA','SB','SC','SD','SE','SG','SH','SI','SJ','SK','SL','SM','SN','SO','SR','SS','ST','SV','SX','SY','SZ','TC','TD','TF','TG','TH','TJ','TK','TL','TM','TN','TO','TR','TT','TV','TW','TZ','UA','UG','UM','US','UY','UZ','VA','VC','VE','VG','VI','VN','VU','WF','WS','YE','YT','ZA','ZM','ZW'];
const weightUnitCode = ['kg', 'g'];
const yyCode = ['22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49'];
const mmCode = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const invoiceIssueDateCode = [
    { display: '3th of the month', value: 3 },
    { display: '4th of the month', value: 4 },
    { display: '5th of the month', value: 5 },
    { display: '6th of the month', value: 6 },
    { display: '7th of the month', value: 7 },
]
const billingDateCode = [
    { display: 'Last Day of the month', value: 31 },
];
const paymentCode = [
    { display: 'Monthly', value: 'MONTHLY' },
    { display: 'Card', value: 'CARD' },
    { display: 'Link', value: 'LINK' },
];
const rateCode = ['MARKUP', 'FIXED']
const otherChargeType = [
    { display: 'KR Parcel Delivery', value: 'PARCEL_DELIVERY' },
    { display: 'KR Quick Delivery', value: 'QUICK_DELIVERY' },
    { display: 'KR Truck Delivery', value: 'TRUCK_DELIVERY' },
    { display: 'Customs Clearance', value: 'CUSTOM_CLEARANCE' },
    { display: 'Repacking', value: 'REPACKING' },
    { display: 'Wooden Packing (Pallet)', value: 'WOODEN_PACKING' },
    { display: 'ETC', value: 'ETC' },
]
const supportingDocumentType = [
    { display:"REVENUE", value:"REVENUE" },
    { display:"COST", value:"COST" },
]

const trackingEvent = [
    { event: "ORDER_PLACED", desc: "Order Placed", abbreviation: "Order Placed" },
    { event: "LABEL_CREATED", desc: "Label Created", abbreviation: "Label Created" },
    { event: "AWB_CREATED", desc: "Preparing to Ship", abbreviation: "Preparing to Ship" },
    { event: "GEM_CENTER_ARRIVED", desc: "GEM Korea Center Arrived", abbreviation: "GEM Center Arrived" },
    { event: "READY_SHIP", desc: "Ready to Ship", abbreviation: "Ready to Ship" },
    { event: "SHIPPED", desc: "Shipped", abbreviation: "Shipped" },
    { event: "DEST_COUNTRY_ARRIVED", desc: "Arrived in Destination Country", abbreviation: "Arrived in" },
    { event: "CLEARANCE_DELAY", desc: "Delays in Customs Clearance", abbreviation: "Delays in Clearance" },
    { event: "IN_CLEARANCE", desc: "Customs Clearance in Progress", abbreviation: "Clearance in Progress" },
    { event: "CLEARED", desc: "Customs Clearance Completed", abbreviation: "Clearance Completed" },
    { event: "FINAL_DEST_TRANSIT", desc: "In Transit to Final Destination", abbreviation: "In Transit" },
    { event: "FINAL_DEST_ARRIVED", desc: "Arrived at Final Destination", abbreviation: "Arrived in" },
    { event: "IN_DELIVERY", desc: "Out for Delivery", abbreviation: "Out for Delivery" },
    { event: "DELIVERED", desc: "Delivered", abbreviation: "Delivered" },
    { event: "IN_TRANSIT", desc: "In Transit", abbreviation: "In Transit" },
    { event: "PICKUP_DELAY", desc: "Pickup Delivered", abbreviation: "Pickup Delivered" },
    { event: "DELIVERY_DELAY", desc: "Delays in Delivery", abbreviation: "Delays in Delivery" },
    { event: "DELIVERY_EXCEPTION", desc: "Delivery exception", abbreviation: "Delivery exception" },
    { event: "HUB_ARRIVED", desc: "Arrived at Hub", abbreviation: "Arrived at Hub" },
    { event: "AWB_CANCEL", desc: "AWB Cancel", abbreviation: "AWB Cancel" },
    { event: "RETURN", desc: "Return", abbreviation: "Return" },
]

function getOtherChargeTypeDisplay(name) {
    let result = "Check";
    for(let index=0; index < otherChargeType.length; index++) {
        if(name == otherChargeType[index].value) {
            result = otherChargeType[index].display;
        }
    }
    return result;
}

function getTrackingEventAbbreviation(event, countryCode, city) {
    let result = "Check";
    for(let index=0; index < trackingEvent.length; index++) {
        if(event == trackingEvent[index].event) {
            result = trackingEvent[index].abbreviation;
            if(trackingEvent[index].event == "DEST_COUNTRY_ARRIVED") { result += " " + countryCode; }
            if(trackingEvent[index].event == "FINAL_DEST_ARRIVED") { result += " " + city; }
        }
    }
    return result;
}

function getTrackingEventDesc(event, city, countryCode) {
    let result = "Tracking Event Description Check";
    for(let index=0; index < trackingEvent.length; index++) {
        if(event == trackingEvent[index].event) {
            if(city !== null &&  countryCode !== null) { result = trackingEvent[index].desc + " - " + city + ", " + countryCode; }
            else if(city !== null && countryCode === null) { result = trackingEvent[index].desc + " - " + city; }
            else if(city === null && countryCode !== null) { result = trackingEvent[index].desc + " - " + countryCode; }
            else { result = trackingEvent[index].desc; }
        }
    }
    return result;
}

export default {
    getOtherChargeTypeDisplay,
    countryCode,
    weightUnitCode,
    invoiceIssueDateCode,
    billingDateCode,
    paymentCode,
    rateCode,
    yyCode,
    mmCode,
    supportingDocumentType,
    getTrackingEventAbbreviation,
    getTrackingEventDesc,
}