function makeAPIUrl(path) {
    return process.env.VUE_APP_API_PROXYPASS + path;
}

function getCountry(value) {
    return "vti__flag " + value.toLowerCase();
}

function stateCodeCheck(countryCode, state) {
    let result = false
    let checkStateCA = ["AB", "BC", "MB", "NB", "NL", "NT", "NS", "NU", "ON", "PE", "QC", "SK", "YT"]
    let checkStateIN = [
        "AN",
        "AP",
        "AR",
        "AS",
        "BR",
        "CG",
        "CH",
        "DD",
        "DL",
        "DN",
        "GA",
        "GJ",
        "HR",
        "HP",
        "JK",
        "JH",
        "KA",
        "KL",
        "LD",
        "MP",
        "MH",
        "MN",
        "ML",
        "MZ",
        "NL",
        "OR",
        "PB",
        "PY",
        "RJ",
        "SK",
        "TN",
        "TR",
        "UA",
        "UP",
        "WB",
    ]
    let checkStateMX = [
        "AG",
        "BC",
        "BS",
        "CM",
        "CS",
        "CH",
        "DF",
        "CO",
        "CL",
        "DG",
        "EM",
        "GT",
        "GR",
        "HG",
        "JA",
        "MI",
        "MO",
        "NA",
        "NL",
        "OA",
        "PU",
        "QE",
        "QR",
        "SL",
        "SI",
        "SO",
        "TB",
        "TM",
        "TL",
        "VE",
        "YU",
        "ZA",
    ]
    let checkStateUS = [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "PR",
    ]
    let checkStateAE = ["AB", "AJ", "DU", "FU", "RA", "SH", "UM"]

    if (countryCode.toUpperCase() != "CA" && countryCode.toUpperCase() != "IN" && countryCode.toUpperCase() != "MX" && countryCode.toUpperCase() != "US" && countryCode.toUpperCase() != "AE") {
        return true
    }
    if (countryCode.toUpperCase() == "CA") {
        for (let checkStateCount = 0; checkStateCount < checkStateCA.length; checkStateCount++) {
            if (state.toUpperCase() == checkStateCA[checkStateCount].toUpperCase()) {
                result = true
            }
        }
    }
    if (countryCode.toUpperCase() == "IN") {
        for (let checkStateCount = 0; checkStateCount < checkStateIN.length; checkStateCount++) {
            if (state.toUpperCase() == checkStateIN[checkStateCount].toUpperCase()) {
                result = true
            }
        }
    }
    if (countryCode.toUpperCase() == "MX") {
        for (let checkStateCount = 0; checkStateCount < checkStateMX.length; checkStateCount++) {
            if (state.toUpperCase() == checkStateMX[checkStateCount].toUpperCase()) {
                result = true
            }
        }
    }
    if (countryCode.toUpperCase() == "US") {
        for (let checkStateCount = 0; checkStateCount < checkStateUS.length; checkStateCount++) {
            if (state.toUpperCase() == checkStateUS[checkStateCount].toUpperCase()) {
                result = true
            }
        }
    }
    if (countryCode.toUpperCase() == "AE") {
        for (let checkStateCount = 0; checkStateCount < checkStateAE.length; checkStateCount++) {
            if (state.toUpperCase() == checkStateAE[checkStateCount].toUpperCase()) {
                result = true
            }
        }
    }

    return result
}

export default {
    makeAPIUrl,
    getCountry,
    stateCodeCheck,
}