import axios from "axios";
import commfn from "@/common/Function.js"

function getAccessToken() {
    const url = process.env.VUE_APP_AUTH_API_URL;
    const data = new FormData();
    data.append("grant_type", "password");
    data.append("username", process.env.VUE_APP_AUTH_USERNAME)
    data.append("password", process.env.VUE_APP_AUTH_PASSWORD)
    const config = {
        headers: {
        Authorization: process.env.VUE_APP_AUTH_TOKEN,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json; charset = utf-8",
        },
    };

    return axios.post(url, data, config);
}

function login(email, password, authorization) {
    const url = commfn.makeAPIUrl("/prework/admin/login");
    const data = { email: email, password: password };
    const config = { headers: {"Authorization": authorization}};

    return axios.put(url, data, config);
}

function getWaitingApproval(authorization) {
    const url = commfn.makeAPIUrl("/sellers?status=NEW");
    const config = { headers: {"Authorization": authorization}};

    return axios.get(url, config);
}

function getSellerList(authorization) {
    const url = commfn.makeAPIUrl("/sellers?status=APPROVE");
    const config = { headers: {"Authorization": authorization}};

    return axios.get(url, config);
}

function getSeller(id, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + id);
    const config = { headers: {"Authorization": authorization}};

    return axios.get(url, config);
}

function setSellerModify(id, params, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + id);
    const data = { email: params.email, firstName: params.firstName, lastName: params.lastName, phoneNo: params.phoneNo, companyName: params.companyName, companyRegisterNo: params.companyRegisterNo,
        companyTelNo: params.companyTelNo, addressCountry: params.addressCountry, addressCity: params.addressCity, addressState: params.addressState, address1: params.address1, 
        address2: params.address2, zipCode: params.zipCode };
    const config = { headers: {"Authorization": authorization}};

    return axios.put(url, data, config);
}

function getSellerStoreList(id, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + id + "/stores");
    const config = { headers: {"Authorization": authorization}};

    return axios.get(url, config);
}

function setSellerStoreModify(id, storeId, params, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + id + "/stores");
    const data = { sellerId: id, storeId: storeId, platform: params.platform, name: params.name, url: params.url, isIntegration: params.isIntegration }
    const config = { headers: {"Content-Type": "application/json", "Authorization": authorization } };

    return axios.put(url, data, config);
}

function setSellerStoreDelete(id, rateId, authorization) {
    const config = {
        method: "delete",
        url: commfn.makeAPIUrl("/sellers/" + id + "/stores/" + rateId),
        headers: {
        Authorization: authorization,
            "Content-Type": "application/json",
        },
    };          

    return axios(config);
}

function setApproval(sellerId, recommenderId, payment, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + sellerId + "/approve");
    const data = { sellerId: sellerId, recommenderId: recommenderId, payment: payment };
    const config = { headers: {"Content-Type": "application/json", "Authorization": authorization } };

    return axios.put(url, data, config);
}

function getRecommender(id, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + id + "/recommender");
    const config = { headers: { "Authorization": authorization } };

    return axios.get(url, config);
}

function getRecommendee(id, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + id + "/recommendee");
    const config = { headers: { "Authorization": authorization } };

    return axios.get(url, config);
}

function getRateList(id, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + id + "/rates");
    const config = { headers: { "Authorization": authorization } };

    return axios.get(url, config);
}

function setRateRegistration(id, params, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + id + "/rates");
    const data = {
        sellerId: id,
        carrier: params.carrier,
        carrierService: params.carrierService,
        origin: params.origin,
        destination: params.destination,
        rateType: params.rateType,
        markupValue: params.markupValue,
        fixedRateCurrency: params.fixedRateCurrency,
        fixedRateValue: params.fixedRateValue,
        fixedRateUnit: params.fixedRateUnit,
        isActive: params.isActive,
    }
    const config = { headers: {"Content-Type": "application/json", "Authorization": authorization } };

    return axios.post(url, data, config);
}

function setRateModify(id, rateId, params, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + id + "/rates");
    const data = {
        id: rateId,
        sellerId: id,
        carrier: params.carrier,
        carrierService: params.carrierService,
        origin: params.origin,
        destination: params.destination,
        rateType: params.rateType,
        markupValue: params.markupValue,
        fixedRateCurrency: params.fixedRateCurrency,
        fixedRateValue: params.fixedRateValue,
        fixedRateUnit: params.fixedRateUnit,
        isActive: params.isActive,
    }
    const config = { headers: {"Content-Type": "application/json", "Authorization": authorization } };

    return axios.put(url, data, config);    
}

function setRateDelete(id, rateId, authorization) {
    const config = {
        method: "delete",
        url: commfn.makeAPIUrl("/sellers/" + id + "/rates/" + rateId),
        headers: {
        Authorization: authorization,
            "Content-Type": "application/json",
        },
    };          

    return axios(config);
}

function setRecommenderDelete(id, authorization) {
    const config = {
        method: "delete",
        url: commfn.makeAPIUrl("/sellers/" + id + "/recommender"),
        headers: {
        Authorization: authorization,
            "Content-Type": "application/json",
        },
    };          

    return axios(config);
}

function setRecommenderModify(id, recommenderId, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + id + "/recommender");
    const data = { recommenderId: recommenderId };
    const config = { headers: {"Content-Type": "application/json", "Authorization": authorization } };
    
    return axios.patch(url, data, config);   
}

function getCarrierList(authorization) {
    const url = commfn.makeAPIUrl("/carriers");
    const config = { headers: { "Authorization": authorization } };

    return axios.get(url, config)    
}

function getCardList(id, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + id + "/cards");
    const config = { headers: { "Authorization": authorization } };

    return axios.get(url, config);     
}

function setCardModify(id, cardId, params, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + id + "/cards/" + cardId + "/state");
    const data = { isActive: params.isActive, isDefault: params.isDefault };
    const config = { headers: {"Content-Type": "application/json", "Authorization": authorization } };

    return axios.patch(url, data, config);   
}

function getPayment(id, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + id + "/payment");
    const config = { headers: { "Authorization": authorization } };

    return axios.get(url, config);
}

function getPaymentModify(id, params, authorization) {
    const url = commfn.makeAPIUrl("/sellers/" + id + "/payment");
    const data = { paymentType: params.paymentType, autopayCurrency: "KRW", autopayValue: params.autopayValue, invoiceDay: params.invoiceDay, billingDay: params.billingDay };
    const config = { headers: { "Authorization": authorization } };

    return axios.put(url, data, config);
}

function getStatistics(authorization) {
    const url = commfn.makeAPIUrl("/sellers/statistics");
    const config = { headers: { "Authorization": authorization } };

    return axios.get(url, config)
}

function getPaymentList(fromDate, toDate, searchText, authorization) {
    const url = commfn.makeAPIUrl('/payments?userType=ADMIN&sellerId=&startDate=' + fromDate + '&endDate=' + toDate + '&keyword=' + searchText + '');
    const config = { headers: { "Authorization": authorization } };

    return axios.get(url, config)
}

function getPaymentDetail(paymentId, authorization) {
    const url = commfn.makeAPIUrl('/payments/' + paymentId + '/detail');
    const config = { headers: { "Authorization": authorization } };

    return axios.get(url, config)
}

function setPaymentCancel(parmas, authorization) {
    const url = commfn.makeAPIUrl("/payments/cancel");
    const data = parmas;
    const config = { headers: {"Content-Type": "application/json", "Authorization": authorization } };

    return axios.post(url, data, config);
}

function getPaymentExcel(parmas, authorization) {
    const url = commfn.makeAPIUrl("/payments/download?paymentIds=" + parmas);
    const config = { headers: {"Content-Type": "application/json", "Authorization": authorization } };

    return axios.get(url, config);
}

function setPaymentWaitCancel(parmas, authorization) {
    const url = commfn.makeAPIUrl("/payments/wait-cancel");
    const data = parmas;
    const config = { headers: {"Content-Type": "application/json", "Authorization": authorization } };

    return axios.put(url, data, config);
}

function setPartnerRegistration(params, authorization) {
    const url = commfn.makeAPIUrl("/invoice-companies");
    const data = params;
    const config = { headers: {"Content-Type": "application/json", "Authorization": authorization } };

    return axios.post(url, data, config);
}

function getPartnerList(searchText, authorization) {
    const url = commfn.makeAPIUrl("/invoice-companies?name=" + searchText);
    const config = { headers: {"Authorization": authorization}};

    return axios.get(url, config);
}

function setPartnerModify(params, authorization) {
    const url = commfn.makeAPIUrl("/invoice-companies/" + params.id);
    const data = params;
    const config = { headers: {"Authorization": authorization}};

    return axios.put(url, data, config);
}

function setPartnerDelete(ids, authorization) {
    const config = {
        method: "delete",
        url: commfn.makeAPIUrl("/invoice-companies/"),
        data: ids,
        headers: {
        Authorization: authorization,
            "Content-Type": "application/json",
        },
    };          

    return axios(config);
}

function getInvoiceList(searchText, startDate, endDate,  authorization) {
    const url = commfn.makeAPIUrl("/invoices?keyword=" + searchText + "&startDate=" + startDate + "&endDate=" + endDate);
    const config = { headers: {"Authorization": authorization}};

    return axios.get(url, config);
}

function setInvoiceDelete(ids, authorization) {
    const config = {
        method: "delete",
        url: commfn.makeAPIUrl("/invoices"),
        data: ids,
        headers: {
        Authorization: authorization,
            "Content-Type": "application/json",
        },
    };          

    return axios(config);
}

function setInvoiceRegistration(params, authorization) {
    const url = commfn.makeAPIUrl("/invoices");
    const data = params;
    const config = { headers: {"Content-Type": "application/json", "Authorization": authorization } };

    return axios.post(url, data, config);
}

function setInvoiceModify(params, authorization) {
    const url = commfn.makeAPIUrl("/invoices/" + params.id);
    const data = params;
    const config = { headers: {"Authorization": authorization}};

    return axios.put(url, data, config);
}

function getInvoiceDuplicate(invoiceNo,  authorization) {
    const url = commfn.makeAPIUrl("/invoices/is-duplicate?invoiceNo=" + invoiceNo);
    const config = { headers: {"Authorization": authorization}};

    return axios.get(url, config);
}

// ORDER API
function getOrderList(fromDate, toDate, sellerId, searchText, authorization) {
    const url = commfn.makeAPIUrl('/orders?sellerId='+ sellerId +'startDate=' + fromDate + '&endDate=' + toDate + '&keyword=' + searchText + '');
    const config = { headers: { "Authorization": authorization } };

    return axios.get(url, config)
}

function getOrderDetail(orderId, authorization) {
    const url = commfn.makeAPIUrl('/orders/' + orderId);
    const config = { headers: { "Authorization": authorization } };

    return axios.get(url, config)
}

function setOrderRegistration(params, authorization) {
    const url = commfn.makeAPIUrl("/orders/");
    const data = params;
    const config = { headers: {"Content-Type": "application/json", "Authorization": authorization } };

    return axios.post(url, data, config);
}

function setOrderModify(orderId, params, authorization) {
    const url = commfn.makeAPIUrl("/orders/" + orderId);
    const data = params;
    const config = { headers: { "Authorization": authorization } };

    return axios.put(url, data, config);
}

function setOrderDelete(id, data, authorization) {
    const config = {
        method: "delete",
        url: commfn.makeAPIUrl("/oders/"),
        headers: {
        Authorization: authorization,
            "Content-Type": "application/json",
        },
        data: data,
    };          

    return axios(config);
}

function createAPIInfo(sellerId, apiClient, authorization) {
    const url = commfn.makeAPIUrl("/openapi")
    const data = {
        sellerId: sellerId,
        apiClient: apiClient
    }
    const config = { headers: { "Content-Type": "application/json", "Authorization": authorization } }

    return axios.post(url, data, config)
}

function findAPIInfo(sellerId, authorization) {
  const url = commfn.makeAPIUrl("/openapi?sellerId=" + sellerId)
  const config = { headers: { Authorization: authorization } }

  return axios.get(url, config)
}

function getCarrier(authorization) {
    const url = commfn.makeAPIUrl("/carriers/");
    const config = { headers: { "Authorization": authorization } };

    return axios.get(url, config);
}

function setCarrierModify(params, authorization) {
    const url = commfn.makeAPIUrl("/carriers/");
    const data = params;
    const config = { headers: {"Authorization": authorization}};

    return axios.put(url, data, config);
}


export default {
    getAccessToken,login,getWaitingApproval,getSellerList,setApproval,getRateList,setRateRegistration,setRateModify,setRateDelete,getCarrierList,getSeller
    ,getSellerStoreList,setSellerStoreDelete,setSellerStoreModify,setSellerModify,getCardList,setCardModify,getPayment,getPaymentModify,getRecommender,getRecommendee
    ,setRecommenderDelete,setRecommenderModify,getStatistics,getPaymentList,getPaymentDetail,setPaymentCancel,getPaymentExcel,setPaymentWaitCancel,setPartnerRegistration
    ,getPartnerList,setPartnerModify,setPartnerDelete,getInvoiceList,setInvoiceDelete,setInvoiceRegistration,setInvoiceModify,getInvoiceDuplicate
    ,getOrderList,getOrderDetail,setOrderRegistration,setOrderModify,setOrderDelete
    ,findAPIInfo, createAPIInfo, getCarrier, setCarrierModify
};
