import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/DashboardView.vue'),
  },
  {
    path: '/seller-approval',
    name: 'seller-approval',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/seller/ApprovalView.vue'
      ),
  },
  {
    path: '/seller-general-info',
    name: 'seller-general-info',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/seller/GeneralInfoView.vue'
      ),
  },
  {
    path: '/seller-rate',
    name: 'seller-rate',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/seller/RateView.vue'),
  },
  {
    path: '/seller-list',
    name: 'seller-list',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/seller/ListView.vue'),
  },
  {
    path: '/payment-list',
    name: 'payment-list',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/payment/ListView.vue'),
  },
  {
    path: '/order-list',
    name: 'order-list',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/order/ListView.vue'),
  },
  {
    path: '/shipping-list',
    name: 'shipping-list',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/shipping/ListView.vue'),
  },
  {
    path: '/invoice-supporting-document',
    name: 'invoice-supporting-document',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/invoice/SupportingDocumentView.vue'
      ),
  },
  {
    path: '/partner-list',
    name: 'partner-list',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/partner/ListView.vue'),
  },
  {
    path: '/carrier-list',
    name: 'carrier-list',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/carrier/ListView.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
