<template>
  <v-app>
    <v-row class="ma-0 pa-0">
      <v-col cols="2" id="leftMenu" style="display: block">
        <template>
          <v-row>
            <v-col align="center" class="ma-0 pa-0">
              <v-img
                alt=""
                class="shrink mt-1 hidden-sm-and-down"
                contain
                src="./images/top_logo.png"
                width="250px"
              />
            </v-col>
          </v-row>
          <v-card class="mx-auto" width="300" style="margin-top: 20px">
            <v-list>
              <v-list-item id="dashboard" @click="leftMenuClick('dashboard')">
                <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item>

              <v-list-group
                v-bind:value="leftMenuGroup.partner"
                prepend-icon="mdi-account-supervisor-circle"
              >
                <template v-slot:activator>
                  <v-list-item-title>Partner</v-list-item-title>
                </template>
                <v-list-item
                  id="partner-list"
                  @click="leftMenuClick('partner-list')"
                >
                  <v-list-item-title style="margin-left: 60px"
                    >List</v-list-item-title
                  >
                </v-list-item>
              </v-list-group>

              <v-list-group
                v-bind:value="leftMenuGroup.invoice"
                prepend-icon="mdi-receipt-text-outline"
              >
                <template v-slot:activator>
                  <v-list-item-title>Invoice</v-list-item-title>
                </template>
                <v-list-item
                  id="invoice-supporting-document"
                  @click="leftMenuClick('invoice-supporting-document')"
                >
                  <v-list-item-title style="margin-left: 60px"
                    >Supporting Document</v-list-item-title
                  >
                </v-list-item>
              </v-list-group>

              <v-list-group
                v-bind:value="leftMenuGroup.seller"
                prepend-icon="mdi-account-circle"
              >
                <template v-slot:activator>
                  <v-list-item-title>Seller</v-list-item-title>
                </template>
                <v-list-item
                  id="seller-approval"
                  @click="leftMenuClick('seller-approval')"
                >
                  <v-list-item-title style="margin-left: 60px"
                    >Approval</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  id="seller-general-info"
                  @click="leftMenuClick('seller-general-info')"
                >
                  <v-list-item-title style="margin-left: 60px"
                    >General Info</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  id="seller-rate"
                  @click="leftMenuClick('seller-rate')"
                >
                  <v-list-item-title style="margin-left: 60px"
                    >Rate</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  id="seller-list"
                  @click="leftMenuClick('seller-list')"
                >
                  <v-list-item-title style="margin-left: 60px"
                    >List</v-list-item-title
                  >
                </v-list-item>
              </v-list-group>

              <v-list-group
                v-bind:value="leftMenuGroup.payment"
                prepend-icon="mdi-credit-card-marker-outline"
              >
                <template v-slot:activator>
                  <v-list-item-title>Payment</v-list-item-title>
                </template>
                <v-list-item
                  id="payment-list"
                  @click="leftMenuClick('payment-list')"
                >
                  <v-list-item-title style="margin-left: 60px"
                    >List</v-list-item-title
                  >
                </v-list-item>
              </v-list-group>

              <v-list-group
                v-bind:value="leftMenuGroup.order"
                prepend-icon="mdi-update"
              >
                <template v-slot:activator>
                  <v-list-item-title>Order</v-list-item-title>
                </template>
                <v-list-item
                  id="order-list"
                  @click="leftMenuClick('order-list')"
                >
                  <v-list-item-title style="margin-left: 60px"
                    >List</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  id="order-rate"
                  @click="leftMenuClick('order-rate')"
                >
                  <v-list-item-title style="margin-left: 60px"
                    >Rate</v-list-item-title
                  >
                </v-list-item>
              </v-list-group>
              <v-list-group
                v-bind:value="leftMenuGroup.shipping"
                prepend-icon="mdi-airplane"
              >
                <template v-slot:activator>
                  <v-list-item-title>Shipping</v-list-item-title>
                </template>
                <v-list-item
                  id="shipping-list"
                  @click="leftMenuClick('shipping-list')"
                >
                  <v-list-item-title style="margin-left: 60px"
                    >List</v-list-item-title
                  >
              </v-list-item>
              </v-list-group>
              <v-list-group
                v-bind:value="leftMenuGroup.carrier"
                prepend-icon="mdi-truck-fast"
              >
                <template v-slot:activator>
                  <v-list-item-title>Carrier</v-list-item-title>
                </template>
                <v-list-item
                  id="carrier-list"
                  @click="leftMenuClick('carrier-list')"
                >
                  <v-list-item-title style="margin-left: 60px"
                    >List</v-list-item-title
                  >
              </v-list-item>
              </v-list-group>
            </v-list>
            <v-list-item link @click="logout()">
              <v-list-item-icon
                ><v-icon>mdi-account-off-outline</v-icon></v-list-item-icon
              >
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="leftMenu('hide')">
              <v-list-item-icon
                ><v-icon>mdi-swap-horizontal</v-icon></v-list-item-icon
              >
              <v-list-item-title>Hidden</v-list-item-title>
            </v-list-item>
          </v-card>
        </template>
      </v-col>
      <v-col>
        <div style="position: absolute">
          <v-list-item-icon
            @click="leftMenu('show')"
            style="cursor: pointer; display: none"
            id="leftMenuHide"
            ><v-icon>mdi-swap-horizontal</v-icon></v-list-item-icon
          >
        </div>
        <router-view />
      </v-col>
    </v-row>
  </v-app>
</template>
<style scoped>
.bg-active {
  background-color: rgb(84, 84, 213);
  color: white !important;
}
</style>
<script>
import $ from "jquery";
import api from "@/api/Api.js";

export default {
  name: "App",

  data: () => ({
    leftMenuGroup: {
      seller: false,
      payment: false,
      order: false,
      shipping: false,
      partner: false,
    },
  }),
  methods: {
    getToken() {
      const promise = api.getAccessToken();
      promise
        .then((response) => {
          this.authorization =
            response.data.token_type + " " + response.data.access_token;
          this.$session.set("authorizationAdmin", this.authorization);
        })
        .catch((err) => {
          if (
            err.response.data.error_description === "Bad credentials" ||
            err.response.data.error === "invalid_grant"
          ) {
            this.$alert(
              "사용자 아이디 또는 비밀번호를 확인하시기 바랍니다.",
              "",
              "info"
            ).then(() => {});
          } else if (err.response.data.error === "unauthorized") {
            this.$alert(err.response.data.error_description, "", "info").then(
              () => {}
            );
          } else if (
            err.response.data.error_description === "Bad credentials (AX)"
          ) {
            this.$alert(
              "사용자 계정의 유효 기간이 만료 되었습니다.",
              "",
              "info"
            ).then(() => {});
          } else if (
            err.response.data.error_description === "Bad credentials (AL)"
          ) {
            this.$alert("사용자 계정이 잠겨 있습니다.", "", "info").then(
              () => {}
            );
          } else if (
            err.response.data.error_description === "Bad credentials (CX)"
          ) {
            this.$alert(
              "자격 증명 유효 기간이 만료되었습니다.",
              "",
              "info"
            ).then(() => {});
          } else if (
            err.response.data.error_description === "Bad credentials (NA)"
          ) {
            this.$alert("유효하지 않은 사용자입니다.", "", "info").then(
              () => {}
            );
          } else if (
            err.response.data.error_description === "Bad credentials (UC)"
          ) {
            this.$alert("접근이 허용되지 않은 사용자입니다.", "", "info").then(
              () => {}
            );
          } else if (err.response.data.error_description !== undefined) {
            this.$alert(err.response.data.error_description, "", "info").then(
              () => {}
            );
          }
        });
    },
    leftMenu(display) {
      $("#leftMenu").hide();
      $("#leftMenuHide").hide();

      if (display == "show") {
        $("#leftMenu").show();
      }
      if (display == "hide") {
        $("#leftMenuHide").show();
      }
    },
    logout() {
      this.$session.set("loginIdAdmin", "");
      this.$session.set("authorizationAdmin", "");
      location.href = "/login";
    },
    leftMenuClick(MenuUrl) {
      this.$router.push("/" + MenuUrl);
    },
  },
  mounted() {
    setTimeout(function () {
      $("iframe").hide();
    }, 500);

    this.getToken();
    //1시간마다 토큰 갱신
    setInterval(function () {
      this.getToken();
    }, 3600000);

    if (window.location.href.indexOf("seller") != -1) {
      this.leftMenuGroup.seller = true;
    }
    if (window.location.href.indexOf("payment") != -1) {
      this.leftMenuGroup.payment = true;
    }
    if (window.location.href.indexOf("order") != -1) {
      this.leftMenuGroup.order = true;
    }
    if (window.location.href.indexOf("invoice") != -1) {
      this.leftMenuGroup.invoice = true;
    }
    if (window.location.href.indexOf("partner") != -1) {
      this.leftMenuGroup.partner = true;
    }
  },
  updated() {
    $("#dashboard").removeClass("bg-active");
    $("#seller-approval").removeClass("bg-active");
    $("#seller-general-info").removeClass("bg-active");
    $("#seller-rate").removeClass("bg-active");
    $("#seller-list").removeClass("bg-active");
    $("#payment-list").removeClass("bg-active");
    $("#order-rate").removeClass("bg-active");
    $("#order-list").removeClass("bg-active");
    $("#shipping-list").removeClass("bg-active");
    $("#invoice-supporting-document").removeClass("bg-active");
    $("#partner-list").removeClass("bg-active");

    let UrlSplit = window.location.href.split("/");
    let leftPosition = UrlSplit[UrlSplit.length - 1];
    $("#" + leftPosition).addClass("bg-active");
  },
};
</script>
