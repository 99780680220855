import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Code from '@/common/Code'
import VueCountryCode from "vue-country-code";
import VueCookies from "vue-cookies"
import VueSession from "vue-session";
import VueSimpleAlert from "vue-simple-alert";
import Print from "vue-print-nb";

var sessionOptions = {
  persist: true,
};

// EventBus 생성
Vue.prototype.$EventBus = new Vue()

Vue.use(Print);
Vue.use(Code);
Vue.use(VueCountryCode);
Vue.use(VueCookies);
Vue.use(VueSession, sessionOptions);
Vue.use(VueSimpleAlert);

Vue.$cookies.config("30d") // expire 1일 (global 설정)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

Vue.filter("comma", (value) => {
  if(value == null) { value = ""; }
  value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return value;
});

Vue.filter("totalQuantity", (value) => {
  if(value == null) { value = ""; }
  var result = 0;

  for(var count=0; count<value.length; count++){
    result += value[count].itemQuantity
  }
  return result;
});

Vue.filter("currency", (value) => {
  if(value == null) { value = ""; }
  var result = "";
  switch(value.toUpperCase()) {
  case "KRW" : 
    result = "₩"
    break;
  case "USD" : 
    result = "$"
    break;
  case "CNY" : 
    result = "¥"
    break;
  case "RUB" : 
    result = "₽"
    break;
  default : 
    result = value
  }
  return result
});

Vue.filter("yyyymmdd", (value) => {
  let result = "";
  if(value == null) { result = ""; }
  var js_date = new Date(value);
  var year = js_date.getFullYear();
  var month = js_date.getMonth() + 1;
  var day = js_date.getDate();
  var hour = js_date.getHours();
  var minute = js_date.getMinutes();

  if (month < 10) { month = "0" + month; }
  if (day < 10) { day = "0" + day; }
  if (hour < 10) { hour = "0" + hour; }
  if (minute < 10) { minute = "0" + minute; }
  
  result = year + "-" + month + "-" + day;
  return result
});

Vue.filter("zeroToSpace", (value) => {
  if(value == "0") { value = ""; }
  return value;
});

Vue.filter("carrierName", (value) => {
  let result = "";

  if (value != null) {
      switch (value.toUpperCase()) {
          case "UPS":
              result = "UPS"
              break
          case "PX":
              result = "PX"
              break
          case "EMS":
              result = "EMS"
              break
          case "GEM":
              result = "GEM"
              break
          case "FEDEX_FICP":
              result = "FEDEX FICP"
              break
          case "FEDEX_IP":
              result = "FEDEX IP"
              break
          default:
              result = value.toUpperCase()
      }
  }

return result;
});

Vue.filter("timestampToDate", (value) => {
  var result = "";
  var date = new Date(value);
  var year = date.getFullYear().toString(); //년도 뒤에 두자리
  var month = ("0" + (date.getMonth() + 1)).slice(-2); //월 2자리 (01, 02 ... 12)
  var day = ("0" + date.getDate()).slice(-2); //일 2자리 (01, 02 ... 31)
  var hour = ("0" + date.getHours()).slice(-2); //시 2자리 (00, 01 ... 23)
  var minute = ("0" + date.getMinutes()).slice(-2); //분 2자리 (00, 01 ... 59)
  var second = ("0" + date.getSeconds()).slice(-2); //초 2자리 (00, 01 ... 59)

  result = year + "-" + month + "-" + day + " " + hour + ":"  + minute + ":"  + second;

  return result;
});